import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Col, Form, Row } from 'react-bootstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Request from '../customUtils/Http/Request';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "var(--primary-color2)",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "var(--primary-color8) !important"
    },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderStatusTableBody: {
        '& td': {
            fontSize: "16px !important",
            color: "var(--primary-color1) !important",
        }
    },
    tableHeader: {
        backgroundColor: 'var(--primary-color14)',
        '& th': {
            border: "none !important"
        }
    },
    tableContainer: {
        boxShadow: "none !important"
    },
    statusBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px"
    },
    videoBox: {
        margin: "0 auto",
        marginTop: "30px",
        borderRadius: "10px",
        boxShadow: "0px 3px 10px 2px rgba(0, 0, 0, 16%)",
    },
    emptyBox:{
        padding: "40px 0px",
    },
        productRow: {
            paddingTop: "15px",
        },
        productCol: {
            display: "flex",
            alignItems: "center"
        },
        productAdd: {
            backgroundColor: '#DB2D6A !important',
            borderTopRightRadius: '5px !important',
            borderBottomRightRadius: '5px !important',
            color: '#fff !important',
            cursor: "pointer !important"
        },
        productRemove: {
            backgroundColor: '#FFC72C !important',
            borderTopLeftRadius: '5px !important',
            borderBottomLeftRadius: '5px !important',
            color: '#fff !important',
            cursor: "pointer !important"
        },
        
        grid: {
            paddingLeft: "100px !important"
        },
        infoText: {
            position: 'absolute',
            top: '-90px',
            left: '-25%',
            transform: 'translate(30%, 0px)',
            padding: '10px',
            backgroundColor: '#fff',
            color: '#727272',
            borderRadius: '4px',
            fontSize: '14px !important',
            opacity: 0,
            visibility: 'hidden',
            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
            zIndex: '9',
            width: "200px",
            boxShadow: "10px 24px 54px rgba(0, 0, 0, .2)",
            lineHeight: "20px !important"
        },
        icon: {
            color: '#727272 !important',
            fontSize: '20px !important',
            cursor: 'pointer',
            '&:hover': {
                '& ~ $infoText': {
                    opacity: 1,
                    visibility: 'visible',
                },
            },
        },
        triangle: {
            content: '""',
            position: 'absolute',
            bottom: '-15px',
            left: '18%',
            transform: 'translateX(-50%) rotate(0deg)',
            borderTop: '16px solid #fff',
            borderLeft: '0px solid transparent',
            borderRight: '20px solid transparent',
        },
        productRemoveDisabled: {
           opacity:"0.5"
          },
          '@media (max-width: 992px)': {
            dashboardInner: {
                padding: "10px !important",
            },
        },
}));

const Pricing = () => {
    const classes = useStyles();
    const [productInfo, setProductInfo] = useState([]);
    const [boxInfo, setBoxInfo] = useState([]);
    const [islanderInfo, setIslanderInfo] = useState([]);
    const [countryList, setCountryList] = useState([]); 
    const [selectedCountry, setSelectedCountry] = useState('');
    const [shippingRate,setShippingRate] = useState(0);
    const [items, setItems] = useState([]);
    const [boxItems, setBoxItems] = useState([]);
    const [islanderItems, setIslanderItems] = useState([]);

    useEffect(() => {
        fetchData();
        fetchIslanderData();
      }, []);
  
      useEffect(() => {
          if (productInfo?.length > 0) {
            setItems((productInfo && productInfo.length>0) && productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type : "send" })));
          }
      }, [productInfo]);

      useEffect(() => {
        if (boxInfo?.length > 0) {
            setBoxItems((boxInfo && boxInfo.length>0) && boxInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "box" })));
        }
    }, [boxInfo]);

      useEffect(() => {
        if (islanderInfo?.length > 0) {
            setIslanderItems((islanderInfo && islanderInfo.length>0) && islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
        }
    }, [islanderInfo]);

    useEffect(() => {
        if (productInfo?.length > 0) {
            setItems(productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
        }
        if (boxInfo?.length > 0) {
            setBoxItems(boxInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "box" })));
        }
        if (islanderInfo?.length > 0) {
            setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
        }
        setShippingRate(0)
    }, [selectedCountry]);
  
      async function fetchData() {      
          try {
            const response = await Request.get("utils/getShippingRate", []);
            
            if(response){
              setProductInfo(response.sizeListBarrel)
              setBoxInfo(response.sizeListBox)
              setCountryList(response.countryList)
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }

        async function fetchIslanderData() {      
            try {
              const response = await Request.get("utils/getIslanderShippingRateSize", []);
              
              if(response){
                setIslanderInfo(response.sizeList)
                // setCountryList(response.countryList)
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }
  
        function handleCountryChange(event) {
          const selectedValue = event.target.value;
          setSelectedCountry(selectedValue);
          // Now you can do whatever you need with the selectedCountry
      }

    const onClick5 = (size, type) => {
          
        // setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
        setBoxItems(prevBoxItems => {
            const updatedItems = prevBoxItems.map(item => {
                if (item.size === size && item.quantity > 0) {
                    item.quantity -= 1;
                }
                return item;
            });
            addEstimatorRate(type)
            return updatedItems;
        }); 
        setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
    };

  const onClick6 = (size, type) => {
    // setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
      
    setBoxItems(prevBoxItems => {
        const updatedItems = prevBoxItems.map(item => {
            if (item.size === size && item.quantity < 10) {
                item.quantity += 1;
            }
            return item;
        });
        addEstimatorRate(type)
        return updatedItems;
    }); 
    setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
};
  
      const onClick1 = (size, type) => {
            // setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
            setItems(prevItems => {
                const updatedItems = prevItems.map(item => {
                    if (item.size === size && item.quantity > 0) {
                        item.quantity -= 1;
                    }
                    return item;
                });
                addEstimatorRate()
                return updatedItems;
            }); 
            setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
      };
      
      
      const onClick2 = (size) => {
          
        // setTimeout(() => {
        //     setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
        // }, 1000);
         
          setItems(prevItems => {
              const updatedItems = prevItems.map(item => {
                  if (item.size === size && item.quantity < 10) {
                      item.quantity += 1;
                  }
                  return item;
              });
              
              addEstimatorRate()
              return updatedItems;
          });
          setIslanderItems(islanderInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "islander" })));
      };

      const onClick3 = (size) => {
        // setItems(productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
        setIslanderItems(prevItems => {
            const updatedItems = prevItems.map(item => {
                if (item.size === size && item.quantity > 0) {
                    item.quantity -= 1;
                }
                return item;
            });
            addEstimatorRate2()
            return updatedItems;
        });
        setItems(productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
        setBoxItems(boxInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
    };

    const onClick4 = (size) => {
        // setTimeout(() => {
        //     setItems(productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
        // }, 1000);
       
        setIslanderItems(prevItems => {
            const updatedItems = prevItems.map(item => {
                
                if (item.size === size && item.quantity < 10) {
                    item.quantity += 1;
                }
                return item;
                
            });
            addEstimatorRate2()
            return updatedItems;
        });
        setItems(productInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
        setBoxItems(boxInfo.map(sizeObj => ({ size: sizeObj.size, quantity: 0, type: "send" })));
    };

      const addEstimatorRate = async (type) => {
          
        // if(type === "box"){
            const data = {
                "from": "calgary",
                "to": selectedCountry,
                "items" : items.concat(boxItems)
                }
            try {
                const response = await Request.post("utils/getShippingEstimate", data);
                if(response){
                     
                    setShippingRate(response.shippingRate)
                }
              } catch (error) {
                console.error('Error:', error);
              }
    
    }

    const addEstimatorRate2 = async () => {
        
        const data = {
            "from": "calgary",
            "to": selectedCountry,
            "items" : islanderItems
            }
        try {
            const response = await Request.post("utils/getIslandShippingEstimator", data);
            if(response){
                 
                setShippingRate(response.shippingRate)
            }
          } catch (error) {
            console.error('Error:', error);
          }
    }

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                 <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45'>Pricing</Typography>
                        </Col>
                    </Row>
                    <Box sx={{pt: 5,pb: 7,'@media (max-width: 600px)': {pt: 2,pb: 4},}}>
                    <Box className='cartMainContainer'>
                                    <Box className="gridInnerBlockLeft" align="center">
                                        <Box className="estimatorBox">
                                            <Typography className='color1 font-30 font-bold color2 line-40' align="left">Get Your Shipping Estimation</Typography>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                    <Box>
                                                        <Box className="flexBox" sx={{mt: 5,mb: 2,'@media (max-width: 600px)': {mt: 2,mb: 3},}}>
                                                            <Typography className='color3 font-16'>From:</Typography>
                                                            <Typography className='color2 font-16 font-bold calgary-btn'>Calgary</Typography>
                                                            <Typography className='color3 font-16'>To</Typography>
                                                            <Box className="country-select-btn">
                                                                <Form.Select
                                                                    name="country"
                                                                    value={selectedCountry}
                                                                    required
                                                                    onChange={handleCountryChange}
                                                                    // onChange={(e) => handleInputChange(e, "shippingAddress")}
                                                                >
                                                                    <option value="">Select country</option>
                                                                    {(countryList && countryList.length>0) && (
                                                                        <>
                                                                            {countryList.map((country) => (
                                                                            <option key={country} value={country}>
                                                                                {country}
                                                                            </option>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                  
                                                                </Form.Select>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Typography className='color1 font-16 font-bold line-22' align="left">Please select product, size and quantity to get the accurate estimation of your shipping</Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={6} className='flexRight ESt-cost-main'>
                                                    <Box align="left" className="ESt-cost">
                                                        <Typography className='font-bold font-22 color4'>Estimated shipping cost</Typography>
                                                        <Typography className='color1 font-38 font-bold est-cost-btn' align='right'>${FormatPrice(shippingRate)}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{pt: 5}}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={11.7} lg={11.7} xl={11.7} className='estimatorBoxLeft'>
                                                            <Typography className='color2 font-25 font-bold estimatorBox-header' align="left">Send Barrel</Typography>
                                                                <Box className='EInnerBox'>
                                                                   
                                                                    <Box sx={{pt:4, pb:4}}>
                                                                    {(items && items.length>0) && items.map((item, index) =>
                                                                       
                                                                            <Row className='sizeRow' key={item.size}>
                                                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                                                                                        <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{item.size} GL</Typography>
                                                                                        {/* <InfoOutlinedIcon className={classes.icon} />
                                                                                        <span className={classes.infoText}>
                                                                                            <span className={classes.triangle}></span>There are many variations of passages of Lorem Ipsum available</span> */}
                                                                                    </Box>
                                                                                </Col>
                                                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                                                    <Box className="quantityBox estimatorQuantityBox">
                                                                                        <Box>
                                                                                            <Button className={`${classes.productRemove} ${selectedCountry === '' ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === ''} onClick={() => onClick1(item.size, item, item.type)}>-</Button>
                                                                                        </Box>
                                                                                        <input type="number" className="cartInput" id={item.size} readOnly value={item.quantity} min="0" max="10"></input>
                                                                                        <Box >
                                                                                            <Button className={`${classes.productAdd} ${selectedCountry === '' || item.quantity >= 10 ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === '' || item.quantity >= 10} onClick={() => onClick2(item.size, item.type)}>+</Button>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Col>
                                                                            </Row>
                                                                           )
                                                                            
                                                                           }
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={11.7} lg={11.7} xl={11.7} className='estimatorBoxLeft'>
                                                            <Typography className='color2 font-25 font-bold estimatorBox-header' align="left">Send Box</Typography>
                                                                <Box className='EInnerBox'>
                                                                   
                                                                    <Box sx={{pt:4, pb:4}}>
                                                                    {(boxItems && boxItems.length>0) && boxItems.map((item, index) =>
                                                                       
                                                                            <Row className='sizeRow' key={item.size}>
                                                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                                                                                        <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{item.size}</Typography>
                                                                                        {/* <InfoOutlinedIcon className={classes.icon} />
                                                                                        <span className={classes.infoText}>
                                                                                            <span className={classes.triangle}></span>There are many variations of passages of Lorem Ipsum available</span> */}
                                                                                    </Box>
                                                                                </Col>
                                                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                                                    <Box className="quantityBox estimatorQuantityBox">
                                                                                        <Box>
                                                                                            <Button className={`${classes.productRemove} ${selectedCountry === '' ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === ''} onClick={() => onClick5(item.size, item, item.type)}>-</Button>
                                                                                        </Box>
                                                                                        <input type="number" className="cartInput" id={item.size} readOnly value={item.quantity} min="0" max="10"></input>
                                                                                        <Box >
                                                                                            <Button className={`${classes.productAdd} ${selectedCountry === '' || item.quantity >= 10 ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === '' || item.quantity >= 10} onClick={() => onClick6(item.size, item.type)}>+</Button>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Col>
                                                                            </Row>
                                                                           )
                                                                            
                                                                           }
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                    <Grid container>
                                                            <Grid item xs={12} sm={12} md={11.7} lg={11.7} xl={11.7} className='estimatorBoxLeft'>
                                                            <Typography className='color2 font-25 font-bold estimatorBox-header' align="left">Islander Box</Typography>
                                                                <Box className='EInnerBox'>
                                                                   
                                                                    <Box sx={{pt:4, pb:4}}>
                                                                    {(islanderItems && islanderItems.length>0) && islanderItems.map((item, index) =>
                                                                       
                                                                            <Row className='sizeRow' key={item.size}>
                                                                                <Col xs={5} sm={5} md={5} lg={5}>
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                                                                                        <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{item.size}</Typography>
                                                                                        {/* <InfoOutlinedIcon className={classes.icon} />
                                                                                        <span className={classes.infoText}>
                                                                                            <span className={classes.triangle}></span>There are many variations of passages of Lorem Ipsum available</span> */}
                                                                                    </Box>
                                                                                </Col>
                                                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                                                    <Box className="quantityBox estimatorQuantityBox">
                                                                                        <Box>
                                                                                            <Button className={`${classes.productRemove} ${selectedCountry === '' ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === ''} onClick={() => onClick3(item.size)}>-</Button>
                                                                                        </Box>
                                                                                        <input type="number" className="cartInput" id={item.size} readOnly value={item.quantity} min="0" max="10"></input>
                                                                                        <Box >
                                                                                            <Button className={`${classes.productAdd} ${selectedCountry === '' || item.quantity >= 10 ? classes.productRemoveDisabled : ''}`} disabled={selectedCountry === '' || item.quantity >= 10} onClick={() => onClick4(item.size)}>+</Button>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Col>
                                                                            </Row>
                                                                           )
                                                                            
                                                                           }
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box sx={{pt: 5,'@media (max-width: 600px)': {pt: 2},}}>
                                                <Typography className='font1 font-16 font-600 line-22' align='left'>
                                                    Disclaimer: These are estimated islander box shipping prices based on the box size, quantity and island you have chosen. The 
                                                   actual price may differ if we need to use a different size box to accommodate the items that you have sent to our warehouse.                         
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default Pricing;
