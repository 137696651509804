import { useEffect, useState } from 'react';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { Col, Row, Form, Dropdown } from 'react-bootstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';

const useStyles = makeStyles((theme) => ({
    productRow: {
        paddingBottom: "15px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    productAdd: {
        backgroundColor: '#EBF7F1 !important',
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',

    },
    productRemove: {
        backgroundColor: '#FAFAFA !important',
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',

    },
    grid: {
        paddingLeft: "100px !important"
    },
    infoText: {
        position: 'absolute',
        top: '-90px',
        left: '-25%',
        transform: 'translate(30%, 0px)',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#727272',
        borderRadius: '4px',
        fontSize: '14px !important',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        zIndex: '9',
        width: "200px",
        boxShadow: "10px 24px 54px rgba(0, 0, 0, .2)",
        lineHeight: "20px !important"
    },
    icon: {
        color: '#727272 !important',
        fontSize: '20px !important',
        cursor: 'pointer',
        '&:hover': {
            '& ~ $infoText': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    triangle: {
        content: '""',
        position: 'absolute',
        bottom: '-15px',
        left: '18%',
        transform: 'translateX(-50%) rotate(0deg)',
        borderTop: '16px solid #fff',
        borderLeft: '0px solid transparent',
        borderRight: '20px solid transparent',
    },
}));


function BarrelList({ barrelInfo, onClick1, onClick2, inputs, type }) {
    const classes = useStyles();

    return (
        <>
            {/* {barrelInfo.length !== 0 && (
                <> */}
                    {(barrelInfo && barrelInfo.length>0) && barrelInfo.map((barrel, index) =>
                        <Box key={index}>
                            <Row className={classes.productRow} >
                                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="sendPriceBox">
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                                        {type === "barrel" ? (
                                            <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{barrel.size} GL</Typography>
                                        ):(
                                            <>
                                             {(barrel.size.toLowerCase() === "l" || barrel.size.toLowerCase() === "large") && (
                                                <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{barrel.size} (20x20x26)</Typography>
                                             )}
                                             {(barrel.size.toLowerCase() === "m" || barrel.size.toLowerCase() === "medium") && (
                                                <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{barrel.size} (18x18x18)</Typography>
                                             )}
                                             {(barrel.size.toLowerCase() === "s" || barrel.size.toLowerCase() === "small") && (
                                                <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{barrel.size} (16x16x16)</Typography>
                                             )}
                                            </>
                                        )}
                                        
                                        {/* <InfoOutlinedIcon className={classes.icon} />
                                        <span className={classes.infoText}>
                                            <span className={classes.triangle}></span>
                                            There are many variations of passages of Lorem Ipsum available</span> */}
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "15px", mt: 0 }}>
                                        <Typography className='priceText font-medium'>${FormatPrice(barrel.price)}</Typography>
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} className={classes.productCol}>
                                    <Box className="quantityBox sendQuantityBox">
                                        <Box onClick={() => onClick1(index)}>
                                            <Button className={classes.productRemove}>-</Button>
                                        </Box>
                                        <input type="text" className="cartInput" id={barrel.id} readOnly value={barrel.quantity} min="0" max="10"></input>
                                        <Box onClick={() => onClick2(index)}>
                                            <Button className={classes.productAdd}>+</Button>
                                        </Box>
                                    </Box>
                                </Col>
                            </Row>
                          
                        </Box>
                    )}
                {/* </>
            )} */}
        </>
    );
}

export default BarrelList;
