import { useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Dialog, DialogContent } from '@mui/material';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form, Dropdown, Table } from 'react-bootstrap';
import { protect } from '../commonFunctions/isValid';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import BarrelList from './barrelList';
import ProductDescription from './productDescription';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderList } from "../redux/islanderListSlice";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Navigate } from 'react-router-dom';
// import { BootstrapInput } from 'react-bootstrap';
const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    grid: {
        paddingLeft: "100px !important",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    countrySelect: {
        padding: "10px",
        minWidth: "135px",
        maxWidth: "200px",
        borderRadius: "5px",
        borderColor: '#EBEBEB',
        color: '#727272',
        backgroundColor: "#fff"
    },
    '@media (max-width: 1200px)': {
        grid: {
        //   paddingLeft: '0px !important',
          padding: '15px 30px !important'
        },
    },
    '@media (max-width: 767px)': {
        grid: {
        //   paddingLeft: '0px !important',
          padding: '15px 10px !important'
        },
        countrySelect:{
            maxWidth: "100%",
            width: "100%"
        },
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
    summaryRoot:{
    minHeight: "40px !important",
    '&::before': {
        content: 'none !important',
      },
    },
    accordianDetail:{
    '& div:last-child div': {
        paddingBottom: 0,
        },
    }
}));

function SendProduct() {
    const classes = useStyles();
    const [size, setSize] = useState('1');
    let [inputs, setInputs] = useState(([
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
    ]));
    let [inputs2, setInputs2] = useState(([
        { value: 0 },
        { value: 0 },
        { value: 0 }
    ]));
    let [num1, setNum1] = useState(0);
    let [num2, setNum2] = useState(0);
    let [barrelInfo, setBarrelInfo] = useState([]);
    let [boxInfo, setBoxInfo] = useState([]);
    const [cartItem, setCartItem] = useState();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedItemOption, setSelectedItemOption] = useState('');
    const [dropdownOption, setDropdownOption] = useState([]);
    const [daysCount, setDaysCount] = useState(0);
    const [itemValue, setItemValue] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [description, setDescription] = useState('');
    const dispatch = useDispatch();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [expanded, setExpanded] = useState('panel1');

    const islanderShippingList = useSelector((state) => state.islanderList.islanderList);


    useEffect(() => {
        dispatch(fetchIslanderList());
    }, [dispatch]);

    
    useEffect(() => {
        dispatch(fetchIslanderList());
    }, []);

      // Function to handle changes in the itemValue field
  const handleItemValueChange = (event) => {
    const newValue = event.target.value;
    setItemValue(newValue);
    updateButtonState(newValue, description);
  };

  // Function to handle changes in the description field
  const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    setDescription(newValue);
    updateButtonState(itemValue, newValue);
  };

  const updateButtonState = (value1, value2) => {
    if (value1 && value2) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        // if(selectedItemOption === ''){
        //     alert('Please select an item first');
        // }
        // else{
            setSelectedOption(selectedValue);

            const selectedOption = dropdownOption.find(option => option.value === selectedValue);
            if (selectedOption) {
                const { days } = selectedOption;
                setDaysCount(days);
            }
        // }
        
    };

    const handleDropdownItemChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedItemOption(selectedValue);

        // const selectedOption = dropdownOption.find(option => option.value === selectedValue);
        // if (selectedOption) {
        //     const { days } = selectedOption;
        //     setDaysCount(days);
        // }
    };

    let login = checkLogin()

    const handleSize = (event) => {
        setSize(event.target.value);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
      };
    const handleContactPage = () => {
        window.location.href = '/contact-us'
    };


    let incNum = (index) => {
        const updatedInputs = [...inputs];
        if (barrelInfo[index].quantity < 10 && barrelInfo[index].quantity >= 0) {
            barrelInfo[index].quantity += 1
            setBarrelInfo(barrelInfo)
            setInputs(updatedInputs);
        }
     else {
        // Show an error dialog when the quantity exceeds 10
        setDialogOpen(true);
      }
    };

    let decNum = (index) => {
        const updatedInputs = [...inputs];
        if (barrelInfo[index].quantity <= 10 && barrelInfo[index].quantity > 0) {
            barrelInfo[index].quantity -= 1;
            setBarrelInfo(barrelInfo)
            setInputs(updatedInputs);
        }
    }

    let incNum2 = (index) => {
        const updatedInputs = [...inputs2];
        if (boxInfo[index].quantity < 10 && boxInfo[index].quantity >= 0) {
            boxInfo[index].quantity += 1
            setBoxInfo(boxInfo)
            setInputs2(updatedInputs);
        }
     else {
        // Show an error dialog when the quantity exceeds 10
        setDialogOpen(true);
      }
    };

    let decNum2 = (index) => {
        const updatedInputs = [...inputs2];
        if (boxInfo[index].quantity <= 10 && boxInfo[index].quantity > 0) {
            boxInfo[index].quantity -= 1;
            setBoxInfo(boxInfo)
            setInputs2(updatedInputs);
        }
    }


    const addToCart = async () => {
        if(selectedOption === '' || itemValue === '' || description === ''){
            setError(true)
            setErrorText('Please select Country')
            return
        }
        var input = document.getElementsByClassName('cartInput');
        var price = document.getElementsByClassName('priceText');
        var size = document.getElementsByClassName('barrelSize');

        const sizeArray = [];
        const productType = [];
        for (let i = 0; i < size.length; i++) {
            const text = size[i].textContent;

            const isGL = text.includes('GL');
            if(isGL){
                const numericPart = text.match(/(\d+)/);
                const value = numericPart ? parseInt(numericPart[0]) : null;
                sizeArray.push({ value });
                productType.push('barrel');
                
            }else{
                const nonBracketValue = text.replace(/\(([^)]+)\)/, '').trim();
                sizeArray.push({ value: nonBracketValue });
                productType.push('box');
            }
        }
        const cartItems = [];
        let totalBarrels = 0
        for (var i = 0; i < sizeArray.length; i++) {
            const item = {
                size: sizeArray[i].value,
                quantity: parseInt(input[i].value),
                productType: productType[i],
            };
            cartItems.push(item);
            if (item.quantity > 0) {
                totalBarrels +=1
            }

        }

        if(totalBarrels === 0){
            setError(true)
            setErrorText("Please add at least one quantity of barrel or box")
            // alert("Please add at least one barrel.")
            return
        }
        else {
            var items = { "to": selectedOption, "items": cartItems, "itemValue" : itemValue ,"description": description}
            let response = await Request.post("send/cart", items, true)
            {
                try {
                    // if (response.message == "Success") {
                    if (response) {
                        window.location.href = '/user/send/checkout'
                    }
                    else {
                        console.error('Failed to fetch data from API');
                    }
                }
                catch (error) {
                    console.error('Error fetching data from API:', error);
                }
            }
        }

    };

    useEffect(() => {
        (async () => {
            try {
              let data = await Request.get(`send/cart/getSendCartItems`,[{country : selectedOption}],true);
                setBarrelInfo(data.sizeListBarrel);
                setBoxInfo(data.sizeListBox);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
              let data = await Request.get(`send/cart/getSendCartItems`,[{country : selectedOption}],true);
                setBarrelInfo(data.sizeListBarrel);
                setBoxInfo(data.sizeListBox);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          })();
    },[selectedOption]);


    useEffect(() => {
        (async () => {
            let data = await Request.get("admin/rate/country", [], true);
            {
                const data2 = (data && data.length>0) && data.map(item => ({
                    value: item.to,
                    label: item.to,
                    days: item.estDeliveryTime
                }));
                // setCartInfo(data.cartItems);
                setDropdownOption(data2)

                const initialSelectedOption = data2.find(option => option.value === selectedOption);
                if (initialSelectedOption) {
                    setDaysCount(initialSelectedOption.days);
                }
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let data = await Request.get("cart", [], true);
            {
                var countList = data.cartItems;
                var count = 0;
                for (var i = 0; i < countList.length; i++) {
                    count += countList[i].quantity;
                }
                // document.getElementById('cartItem').innerHTML = count
            }
        })();
    }, []);
    
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
    
    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Send Barrel/Box</Typography>
                <Box className={classes.headerFlexBox}>
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Send Barrels</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                </Box>
            </Box>
            <Box className='productContainer'>
                <Grid container className="block1" columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pl: 0 }}>
                    <Box className="ProductImageBox">
                            <div className='productOuterBox sendImgOuterBox'>
                                <img src="/assets/img/send-barrel&box.png" className='productListImg' style={{ objectFit: "scale-down" }}/>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", alignItems: "center" }} className={classes.grid}>
                        <Box>
                            <Typography className='font-30 color1 font-bold transform-capital'>Barrel/Box</Typography>
                            <Box className="flexBox" sx={{ mt: 2 }}>
                                <Typography className='font-16 color1 font-16 color1 font-bold transform-capital'>Send Your Barrel/Box To</Typography>
                                <select
                                    size="small"
                                    labelId="countrySelect"
                                    className={classes.countrySelect}
                                    value={selectedOption}
                                    onChange={handleDropdownChange}
                                >
                                    <option value="">Select Country</option>
                                    {(islanderShippingList && islanderShippingList.length>0) && islanderShippingList.map((option) => (
                                        <option key={option.country} value={option.country}>
                                            {option.country}
                                        </option>
                                    ))}
                                </select>

                            </Box>
                            <Box className="flexBox" sx={{ mt: 2 }}>
                                <Typography className='font-16 color1 font-bold'>Estimated  Shipping Time</Typography>
                                <Typography className='font-16 color1 font-bold'>{daysCount} Days</Typography>
                            </Box>
                                {barrelInfo && barrelInfo.length>0 && (
                                    <Accordion expanded={expanded === 'panel1'} className='accordianBlock' onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    classes={{ root: classes.summaryRoot }}
                                    >
                                    <Typography>Select Barrel Size</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: classes.accordianDetail}}>
                                        <BarrelList barrelInfo={barrelInfo} onClick1={decNum} onClick2={incNum} inputs={inputs} type="barrel"/>
                                    </AccordionDetails>
                                    </Accordion>
                                )}
                               {boxInfo && boxInfo.length>0 && (
                                    <Accordion className='accordianBlock' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        classes={{ root: classes.summaryRoot }}
                                        >
                                        <Typography>Select Box Size</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.accordianDetail}}>
                                            <BarrelList barrelInfo={boxInfo} onClick1={decNum2} onClick2={incNum2} inputs={inputs2} type="box"/>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                <Row className='sendBoxPadding'>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <Form.Label className='transform-capital'>Total Value ($)</Form.Label>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                    <Form.Group
                                            controlId="itemValue"
                                            className="edit-order-form__field"
                                    >
                                    <Form.Control
                                                type="number"
                                                name="itemValue"
                                                value={itemValue}
                                                onChange={handleItemValueChange}
                                    />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='sendBoxPadding'>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <Form.Label>Please write a brief description of your Barrel/Box contents</Form.Label>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                    <Form.Group
                                            controlId="description"
                                            className="edit-order-form__field"
                                        >
                                    <Form.Control
                                            as="textarea"
                                            name="description"
                                            value={description}
                                            onChange={handleDescriptionChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                          
                            {/* </Grid> */}
                            <Box sx={{ mt: 5 }}>
                                <Button sx={{ marginBottom: "0px !important" }} variant="contained" className='b1'  disabled={isButtonDisabled} onClick={() => addToCart()}>Proceed to Checkout</Button>
                            </Box>
                            {error && (
                                <Box sx={{mt : 2}}>
                                    <Typography className='font-15 font-600' sx={{color: "red"}}>{errorText}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <ProductDescription />
                        <Typography className='font-22 color2 font-bold mb-1'>Barrel Details</Typography>
                        <Box>
                            <Typography className='mb-3 font-17'>
                                With our fiber drums, you can safely ship a wide range of items, from liquids to non-perishable goods, offering both protection and ease of transportation. Constructed from high-quality fiberboard materials, our drums are a cost-effective and eco-friendly option, offering durability and versatility.
                            </Typography>
                            <Typography className='mb-3 font-17'>
                                Featuring Secure Closure: Each drum is equipped with a robust steel lever-action ring closure mechanism, ensuring a tight seal to prevent leaks, spills, and contamination during storage and transportation.
                            </Typography>
                            <Typography className='mb-3 font-17'>
                            Fiber drums represent a sustainable choice in modern packaging and shipping solutions due to their lightweight construction and recyclable materials.
                            </Typography>
                            <Typography className='mb-3 font-17'>Please consult the table provided below for information regarding shipping weight capacity.</Typography>
                        </Box>
                        <Table responsive="sm" className='send-detail-table'>
                            <thead>
                                <tr>
                                    <th width="50%">Barrel Size</th>
                                    <th>Shipping Weight Capacity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>75 GAL</td>
                                    <td>250 lbs (113kg)</td>
                                </tr>
                                <tr>
                                    <td>55 GAL</td>
                                    <td>200 lbs (91kg)</td>
                                </tr>
                                <tr>
                                    <td>30 GAL</td>
                                    <td>150 lbs (68kg)</td>
                                </tr>
                                <tr>
                                    <td>20 GAL</td>
                                    <td>100 lbs (45kg)</td>
                                </tr>
                            </tbody>
                        </Table>
                   <Typography className='font-22 color2 font-bold mb-1'>Box Details</Typography>
                     <Box>
                            <Typography className='mb-3 font-17'>
                                Whether you're sending essential supplies to a family member, goods for your business, or gifts to friends Isle of Freight boxes offers a range of options to suit your needs.  
                            </Typography>
                            <Typography className='mb-3 font-17'>
                            From sturdy cardboard boxes to specialized packaging solutions, you can trust that your items will be well-protected during transit. Our boxes provide durability, size versatility, standardized dimensions, and secure closures. 
                            </Typography>
                            <Typography className='mb-3 font-17'>
                            The double-walled construction and reinforced corners will provide extra protection for your valuable items.
                            </Typography>
                            {/* <Typography className='mb-3 font-17'>Please consult the table provided below for information regarding shipping weight capacity.</Typography> */}
                        </Box>
                        <Table responsive="sm" className='send-detail-table'>
                            <thead>
                                <tr>
                                    <th width="50%">Box Size</th>
                                    <th>Shipping Weight Capacity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>20x20x26</td>
                                    <td>150 lbs (68kg)</td>
                                </tr>
                                <tr>
                                    <td>18x18x18</td>
                                    <td>100 lbs (45kg)</td>
                                </tr>
                                <tr>
                                    <td>16x16x16</td>
                                    <td>50 lbs (23kg)</td>
                                </tr>
                            </tbody>
                        </Table>
                <Box sx={{pt:2}}>
                    <Typography className='mb-3 font-17 font-bold'>
                        Isle of Freight Inc. is not liable for any item packed in the customer’s barrel/box and for any penalties or restrictions imposed on the customer by the customs department of the recipient island.
                    </Typography>
                    <Typography className='mb-3 font-17 font-bold'>
                    The customer is fully responsible for providing the Service Provider  Isle of Freight) any data, or other documentation necessary for the proper processing of customs or other documentation legally required for handling of the cargo, including but not limited to security checks for freight shipments, such as customs classification.
                    </Typography>
                </Box>
            </Box>
             {/* Material UI Dialog for showing the error */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogContent>
                    The quantity cannot exceed 10
                </DialogContent>
                <Button onClick={handleCloseDialog}>OK</Button>
                <Button onClick={handleContactPage}>Contact Us For Bulk Order</Button>
            </Dialog>
        </Box>


    )
};


export default SendProduct;

