import { useEffect, useState  } from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin, checkUserType } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import Header from '../header/header';
import Footer from '../footer/footer';
import Cart from '../checkout/cart';
import Checkout from '../checkout/checkout';
import BuyProduct from '../product/buyProduct';
import OrderDetail from '../checkout/orderDetail';
import Payment from '../checkout/payment';
import DashboardHeader from '../header/dashboardHeader';
import IslanderAddress from '../islanderBox/islanderAddress';
import Support from '../commonFunctions/support';
import IslanderSendBox from '../islanderBox/islanderSendBox';
import IslanderOrder from '../islanderBox/islanderOrder';
import IslanderOrderStatus from '../islanderBox/islanderOrderStatus';
import IslanderFillBox from '../islanderBox/islanderFillBox';
import IslanderShipment from '../islanderBox/islanderShipment';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import IslanderPayment from '../islanderBox/islanderPayment';
import AdminDashboard from '../admin/adminDashboard';
import Customers from '../admin/customers';
import DriverList from '../admin/driverList';
import DriverProfile from '../admin/driverProfile';
import DriverOrders from '../admin/driverOrders';
import ShippingPrice from '../admin/shippingPrice';
import BaseIslanderAddress from '../admin/baseIslanderAddress';
import IslanderBoxProductList from '../admin/productList';
import IslanderCategories from '../admin/productCategories';
import PaymentStripe from '../admin/paymentStripe';
import PaymentPaypal from '../admin/paymentPaypal';
import PickupLocations from '../admin/pickupLocations';
import AdminSidebar from '../header/adminSidebar';
import Login from '../user/login/login';
import Orders from '../admin/adminOrder';
import ProductList from '../admin/productList';
import TaskManagement from '../admin/taskMAnagement';
import ProductCategories from '../admin/productCategories';
import IslanderShippingList from '../admin/islanderShippingList';
import HelpCenter from '../admin/helpCenter/helpCenter';
import DeliveryTimeList from '../admin/deliveryTimeList';
import QuestionList from '../admin/helpCenter/questionList';
import PriorityProductList from '../admin/priorityProductList';
import PriorityProductCategories from '../admin/priorityProductCategories';
import AdminCustomerReviews from '../admin/adminCustomerReview';
import SubscriberList from '../admin/subscriberList';
import WeeklyDealsList from '../admin/weeklyDealsList';
import CareerVacancyList from '../admin/career/careerVacancyList';
import DeliveryLocations from '../admin/deliveryLocations';
import CommunityList from '../admin/community/communityList';
import TimeSlot from '../admin/timeSlot/timeSlot';
import UsersList from '../admin/usersList';
import TaxList from '../admin/tax';
import PageNotFound from './pageNotFound';
import PrivacyPolicy from '../admin/privacyPolicy/privacyPolicy';


const cookies = new Cookies();

function AdminBoxStructure() {
  // const [userType, setUserType] = useState(null);
  let userType = checkUserType();
  let login = checkLogin()
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  // useEffect(() => {
  //   let userType = cookies.get('userType', { path: '/' });
  //   const userTypeAsNumber = parseInt(userType, 10);
  //   setUserType(userTypeAsNumber)
  // }, []);

  return (
    <> 
     {login === true && (userType === 2 || userType === 1) ? ( 
        <Box className="main">
            <AdminSidebar />
            <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand2'}`}>
                <DashboardHeader />
                <Container maxWidth="l" className="adminInnerPadding">
                    <Box className="adminInner">
                        <Routes>
                            <Route path="/*" element={<PageNotFound />} />
                            <Route path="/admin-dashboard" element={<AdminDashboard />}></Route>
                            <Route path="/orders" element={<Orders />}></Route>
                            <Route path="/customers" element={<Customers />}></Route>
                            <Route path="/driver-list" element={<DriverList />}></Route>
                            <Route path="/task-management" element={<TaskManagement />}></Route>
                            <Route path="/shipping-price" element={<ShippingPrice />}></Route>
                            <Route path="/help-center" element={<QuestionList />}></Route>
                            <Route path="/career" element={<CareerVacancyList />}></Route>
                            <Route path="/base-islander-address" element={<BaseIslanderAddress />}></Route>
                            <Route path="/product-list" element={<ProductList />}></Route>
                            <Route path="/additional-item-list" element={<PriorityProductList />}></Route>                                                                                      
                            <Route path="/product-categories" element={<ProductCategories />}></Route>
                            <Route path="/additional-item-categories" element={<PriorityProductCategories />}></Route>
                            <Route path="/payment-stripe" element={<PaymentStripe />}></Route>
                            <Route path="/payment-paypal" element={<PaymentPaypal />}></Route>
                            <Route path="/delivery-locations" element={<DeliveryLocations />}></Route>
                            <Route path="/pickup-locations" element={<PickupLocations/>}></Route>
                            <Route path="/delivery-time" element={<DeliveryTimeList />}></Route>
                            <Route path="/island-list" element={<IslanderShippingList />}></Route>
                            <Route path="/review-list" element={<AdminCustomerReviews />}></Route>
                            <Route path="/subscribers" element={<SubscriberList />}></Route>
                            <Route path="/community" element={<CommunityList />}></Route>
                            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                            <Route path="/tax" element={<TaxList />}></Route>
                            <Route path="/weeklyDealsList" element={<WeeklyDealsList />}></Route>
                            <Route path="/time-slot" element={<TimeSlot />}></Route>
                            <Route path="/user-list" element={<UsersList />}></Route>
                        </Routes>
                    </Box>
                </Container>
            </Box>
            {/* <Support /> */}
        </Box>
     ) : (
     <>
        <Login />
     </>
     )}
    </>
  )
}


export default AdminBoxStructure;

