import React, { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderShippingPrice, fetchSendShippingPrice, fetchPackagingPrice } from "../redux/shippingPriceSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Request from '../customUtils/Http/Request';
import { Form } from "react-bootstrap";
import { FormatPrice } from "../commonFunctions/commonPriceFunction";
import { CheckNumeric } from "../commonFunctions/checkNumeric";

const initialShippingPrices = [
	{ size: "Small", from: "A", to: "B", price: 10 },
	{ size: "Medium", from: "C", to: "D", price: 20 },
];

const ShippingPriceTable = ({list, toCountryList, fromList, type}) => {
	const [shippingPrices, setShippingPrices] = useState(initialShippingPrices);
	const [selectedPrice, setSelectedPrice] = useState(null);
	const dispatch = useDispatch();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [error, setError] = useState('');
	// const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
		// dimension: "",
		size: "",
		from: "calgary",
		to: "",
		price: "",
	});

	const handleCloseDialog = () => {
		setSelectedPrice(null);
		setIsDialogOpen(false);
	};

	const openDeleteModal = (id) => {
		  
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

	// const ITEM_HEIGHT = 48;

	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const open = Boolean(anchorEl);
	// const handleClick = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	 const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	 const productDelete = async (id) => {

		if(type === "islander"){
			try {
				const response = await Request.delete(`admin/rate/island/shipping/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchIslanderShippingPrice())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else if(type === "send"){
			try {
				const response = await Request.delete(`admin/rate/send/shipping/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchSendShippingPrice())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else if(type === "purchase"){
			try {
				const response = await Request.delete(`admin/rate/purchase/shipping/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchPackagingPrice())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else{

		}
		
	};

	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		// setIsDialogOpen(true);
		  
		if(type === "islander"){
			try {
				const response = await Request.get(`admin/rate/island/shipping/${id}`, [], true);
				if (response.length !== 0) {
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							// dimension: response.dimension,
							size: response.size,
							from: response.from,
							to: response.to,
							price: response.price,
							// "name" : response.name,
							// "status" : response.status
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else if(type === "send"){
			  
			try {
				const response = await Request.get(`admin/rate/send/shipping/${id}`, [], true);
				if (response.length !== 0) {
					  
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							// dimension: response.dimension,
							size: response.size,
							from: response.from,
							to: response.to,
							price: response.price,
							// "name" : response.name,
							// "status" : response.status
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else if(type === "purchase"){
			try {
				const response = await Request.get(`admin/rate/purchase/shipping/${id}`, [], true);
				if (response.id) {
					  
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							// dimension: response.dimension,
							// size: response.size,
							from: response.from,
							to: response.to,
							price: response.price,
							// "name" : response.name,
							// "status" : response.status
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}else{

		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.size || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }
		try {
			// Fetch data from API
			const response = await Request.put(`admin/rate/send/shipping/${selectedProductEdit}`, formData, true);
			if (response.status === 200) {
				
				handleCloseDialog();
				dispatch(fetchSendShippingPrice())
				setFormData({
					size: '',
					// dimension: '',
					from: '',
					to: '',
					weight: '',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	const handleSubmit1 = async (e) => {
		  
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.size || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.put(`admin/rate/island/shipping/${selectedProductEdit}`, formData, true);
			if (response.status === 200) {
				
				handleCloseDialog();
				dispatch(fetchIslanderShippingPrice())
				setFormData({
					size: '',
					from: '',
					to: '',
					weight: '',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};


	const handleSubmit2 = async (e) => {
		  
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.put(`admin/rate/purchase/shipping/${selectedProductEdit}`, formData, true);
			if (response.status === 200) {
				
				handleCloseDialog();
				dispatch(fetchPackagingPrice())
				setFormData({
					size: '',
					from: '',
					to: '',
					weight: '',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	// function isNumeric(value) {
	// 	return !isNaN(parseFloat(value)) && isFinite(value);
	//   }
	  
	//   function checkNumeric(value) {
	// 	if (isNumeric(value)) {
	// 	  return value + "GL";
	// 	} else {
	// 	  return value; // Return the original value if it's not numeric
	// 	}
	//   }

	return (
		<Box className="order-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{type !== "purchase" && (
								<TableCell>Size</TableCell>
							)}
							
							<TableCell>From</TableCell>
							<TableCell>To</TableCell>
							<TableCell>Shipping Price</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(list && list.length>0) && list.map((price) => (
							<TableRow key={price.id}>
									{type !== "purchase" && (
										<TableCell>{CheckNumeric(price.size)}</TableCell>
								)}
								<TableCell>{price.from}</TableCell>
								<TableCell>{price.to}</TableCell>
								<TableCell>${FormatPrice(price.price)}</TableCell>
								<TableCell>
									<IconButton
											aria-label="Edit"
											onClick={() => openEditModal(price.id)} 
										>
											<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										<IconButton
											aria-label="Delete"
											onClick={() => openDeleteModal(price.id)}
										>
											<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this {type} price?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{/* edit dialog */}
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit={type === "send" ? handleSubmit : (type === "islander" ? handleSubmit1 : handleSubmit2)}>
					<DialogTitle className="admin-modal__title-container">
						Edit Shipping Price
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Typography className="font-15 font-600 pb-3" style={{ color: 'red' }}>{error}</Typography>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
					

							{type !== "purchase" && (
								<Form.Group
									controlId="size"
									className="shipping-price-form__field"
								>
									<Form.Label>Size</Form.Label>
									<Form.Control
										type="text"
										value={formData.size}
										placeholder="Enter Size"
										onChange={(e) =>
											handleFieldChange(
												"size",
												e.target.value
											)
										}
									>
										{/* <option value="">Select Size</option>
										<option value="sizes">S</option>
										<option value="sizem">M</option> */}
									</Form.Control>
								</Form.Group>
							)}

							{type === "send" ? (
								<Form.Group
									controlId="from"
									className="shipping-price-form__field"
								>
									<Form.Label>From</Form.Label>
									<Form.Control
										as="select"
										value={formData.from}
										defaultValue="calgary" 
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
									>
										{(fromList && fromList.length>0) && fromList.map(option => (
												<option key={option.city} value={option.city}>
													{option.city}
												</option>
											))}
									</Form.Control>
								</Form.Group>
								):(
								<Form.Group
									controlId="from"
									className="shipping-price-form__field"
								>
									<Form.Label>From</Form.Label>
									<Form.Control
										as="select"
										value={formData.from}
										defaultValue="calgary" 
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
									>
										<option value="calgary">Calgary</option>
									</Form.Control>
								</Form.Group>
								)}


							{type === "purchase" ? (
								<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
										>
											{/* <option value="" disabled>Select an option</option> */}
											{(fromList && fromList.length>0) && fromList.map(option => (
												<option key={option.city} value={option.city}>
													{option.city}
												</option>
											))}
											</Form.Select>
								</Form.Group>
							):(
								<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
										>
											{/* <option value="" disabled>Select an option</option> */}
											{(toCountryList && toCountryList.length>0) && toCountryList.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
											</Form.Select>
								</Form.Group>
							)}
						

								<Form.Group
									controlId="price"
									className="shipping-price-form__field"
								>	
									<Form.Label>Enter Shipping Price</Form.Label>
									<Form.Control
										type="number"
										value={formData.price}
										placeholder="Enter shipping price"
										onChange={(e) =>
											handleFieldChange(
												"price",
												e.target.value
											)
										}
										required
									/>
								</Form.Group>
							</div>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

					{type === "islander" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Edit
						</Button>
					)}
					{type === "send" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Edit
						</Button>
					)}
					{type === "purchase" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Edit
						</Button>
					)}
						
					</DialogActions>
				</form>
			</Dialog>
		</Box>
	);
};

export default ShippingPriceTable;
